import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import {
	PUBLIC_AG_API_ENDPOINT,
	PUBLIC_ENV,
	PUBLIC_SENTRY_SVELTE_DSN,
	PUBLIC_GTM_ID,
	PUBLIC_SENTRY_ON_ERROR_REPLAYS,
	PUBLIC_SENTRY_SESSION_REPLAYS,
	PUBLIC_SENTRY_PROJECT_NAME,
} from '$env/static/public';
import { version } from '$app/environment';

const apiTarget = new URL(PUBLIC_AG_API_ENDPOINT).host;

Sentry.init({
	dsn: PUBLIC_SENTRY_SVELTE_DSN,
	replaysOnErrorSampleRate: Number(PUBLIC_SENTRY_ON_ERROR_REPLAYS) || 1.0,
	replaysSessionSampleRate: Number(PUBLIC_SENTRY_SESSION_REPLAYS) || 0.1,
	// If needed to log errors in dev comment next line
	// Once staging environment is live uncomment end of next line
	enabled: PUBLIC_ENV === 'production', // || PUBLIC_ENV === 'staging',
	debug: PUBLIC_ENV === 'development',
	environment: PUBLIC_ENV,
	tracesSampleRate: 1,
	release: `${PUBLIC_SENTRY_PROJECT_NAME}@${version}`,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		new Replay({
			mask: ['.CardNumberField'],
			maskAllText: false,
			maskAllInputs: false,
			blockAllMedia: false,
			// stripe uses an iframe in its Card component - we get into trouble when Sentry tries to observe it.
			ignore: ['iframe'],
		}),
		new Sentry.BrowserTracing({
			tracePropagationTargets: [apiTarget, 'localhost', '0.0.0.0', '127.0.0.1'],
		}),
	],
});
// Google Tag Manager
const w = window as typeof window & { dataLayer?: unknown[] };
w.dataLayer = w.dataLayer || [];

function gtag(...args: unknown[]) {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	w.dataLayer!.push(args);
}

if (PUBLIC_ENV === 'production') {
	gtag('js', new Date());
	gtag('config', PUBLIC_GTM_ID);
	const s = document.createElement('script');
	s.src = `https://www.googletagmanager.com/gtm.js?id=${PUBLIC_GTM_ID}`;
	document.head.append(s);
}
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
